import { OverridableComponent } from "@mui/material/OverridableComponent";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

export interface MenuItem {
  link?: string;
  icon?: OverridableComponent<any>;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
  link?: string;
  icon?: any;
}

const menuItems: MenuItems[] = [
  {
    heading: "Positions",
    link: "/positions",
    icon: CalendarMonthIcon,
    items: [
      {
        name: "List Positions",
        link: "/positions/list",
      },

      {
        name: "Create Position",
        link: "/positions/add",
      },
      {
        name: "Expired Positions",
        link: "/positions/expiredList",
      },
    ],
  },
  {
    heading: "Candidates",
    link: "/candidates",
    icon: PeopleAltIcon,
    items: [
      {
        name: "List Candidates",
        link: "/candidates/list",
      },
      {
        name: "Add Candidates",
        link: "/candidates/add",
      },
    ],
  },
];

export default menuItems;
