import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  NotificationType,
  showNotification,
} from "../../redux/reducers/notificationSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const TypographyError = styled(Typography)(({ theme }) => ({
  marginLeft: theme.typography.pxToRem(8),
  fontSize: theme.spacing(1.5),
}));

const ForgotPassword: React.FC<{ type: string }> = ({ type }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [linkSent, setLinkSent] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  let { id, token } = useParams();
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleSubmit = async () => {
    if (email == "") {
      setError(true);
    } else {
      try {
        const apiClient = axios.create({
          baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
        });

        const res = await apiClient.post("/generatePasswordLink", {
          email: email,
        });
        if (
          res.status == 200 &&
          res.data == "password reset link sent to your email account"
        ) {
          setLinkSent(true);
        }
      } catch (error: any) {
        if (error?.response?.data == '"email" must be a valid email') {
          dispatch(
            showNotification({
              type: NotificationType.Error,
              message: "Provide a valid email",
            })
          );
        } else {
          dispatch(
            showNotification({
              type: NotificationType.Error,
              message: "Failed! Please reach to support for help",
            })
          );
        }
      }
    }
  };

  const handleSubmitChangePassword = async () => {
    if (password !== confirmPassword) {
      setError(true);
    } else {
      setError(false);
      try {
        const apiClient = axios.create({
          baseURL: `${process.env.REACT_APP_API_ENDPOINT}`,
        });

        const res = await apiClient.post(
          "/updatePassword",
          {
            id: id,
            password: password,
          },
          {
            params: {
              token: token,
            },
          }
        );
        console.log(res);
        if (res.status == 200 && res.data == "password updated successfully") {
          setChangeSuccess(true);
        }
      } catch (error: any) {
        dispatch(
          showNotification({
            type: NotificationType.Error,
            message: "Failed! Please try again",
          })
        );
      }
    }
  };

  useEffect(() => {
    if (type == "change-password") {
      console.log(id);
      console.log(token);
    }
  }, [id, token]);
  return (
    <>
      {type == "send-email" ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: 2 }}>
            <div>
              <img style={{ width: 200 }} src="/assets/prosapiens.png"></img>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "100px",
              }}
            >
              {linkSent ? (
                <>
                  <div className="thankyou">
                    <div style={{ alignItems: "center" }}>
                      An email with the passoword reset link will be sent to
                      your inbox shortly. In case you don't see it within a few
                      minutes, please check your spam folder. If you still can't
                      find it, you can reach our support team at
                      <br />
                      <a href="emailto:support.ann@prosapiens.in">
                        support.ann@prosapiens.in
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                <Grid sx={{ width: "300px", margin: 1 }}>
                  {/* <LabelWrapper color="success">Version 1.1.0</LabelWrapper> */}

                  <Typography sx={{}} variant="h6">
                    Forgot Password
                  </Typography>

                  <Typography sx={{ fontSize: 12, opacity: ".4" }} variant="h6">
                    Enter your registered email
                  </Typography>

                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": {
                        mt: 1,
                        // width: '20ch'
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Stack direction="column">
                      <Box
                        sx={{
                          m: 0,
                        }}
                      >
                        <TextField
                          required
                          id="email"
                          label="Email"
                          fullWidth
                          value={email}
                          margin="dense"
                          onChange={(e) => {
                            setemail(e.target.value);
                            setError(false);
                          }}
                          error={error}
                          helperText={
                            error ? "Please enter valid email to proceed." : ""
                          }
                        />

                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleSubmit()}
                          sx={{ width: "100%", marginTop: "10px" }}
                        >
                          Proceed
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>
              )}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              backgroundColor: "#111D5A",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                height: "300px",
                width: "300px",
                objectFit: "cover",
                display: { xs: "none", md: "block" },
                borderRadius: "250px",
                background: "white",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              }}
              alt="Its a login lander image."
              src="/assets/images/ann.png"
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flex: 2 }}>
            <div>
              <img style={{ width: 200 }} src="/assets/prosapiens.png"></img>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "100px",
              }}
            >
              {changeSuccess ? (
                <>
                  <div className="thankyou">
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ fontSize: 18, marginBottom: "20px" }}>
                        Password successfully changed!
                      </div>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => navigate("/login")}
                        sx={{ width: "300px", marginTop: "10px" }}
                      >
                        Go to Login
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <Grid sx={{ width: "300px", margin: 1 }}>
                  {/* <LabelWrapper color="success">Version 1.1.0</LabelWrapper> */}

                  <Typography sx={{}} variant="h6">
                    Please update your password
                  </Typography>

                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": {
                        mt: 1,
                        // width: '20ch'
                      },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Stack direction="column">
                      <Box
                        sx={{
                          m: 0,
                        }}
                      >
                        <TextField
                          required
                          type={showPassword ? "text" : "password"}
                          id="newPassword"
                          label="New Password"
                          fullWidth
                          value={password}
                          margin="dense"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  onMouseDown={() =>
                                    setShowPassword(!showPassword)
                                  }
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <TextField
                          required
                          type={showNewPassword ? "text" : "password"}
                          id="confirmPassword"
                          label="Confirm New Password"
                          fullWidth
                          value={confirmPassword}
                          margin="dense"
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setError(false);
                          }}
                          onBlur={() => {
                            if (password !== confirmPassword) {
                              setError(true);
                            } else {
                              setError(false);
                            }
                          }}
                          error={error}
                          helperText={error ? "Passwords not matching" : ""}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowNewPassword(!showNewPassword)
                                  }
                                  onMouseDown={() =>
                                    setShowNewPassword(!showNewPassword)
                                  }
                                >
                                  {showNewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => handleSubmitChangePassword()}
                          sx={{ width: "100%", marginTop: "10px" }}
                        >
                          Update Password
                        </Button>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>
              )}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              backgroundColor: "#111D5A",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                height: "300px",
                width: "300px",
                objectFit: "cover",
                display: { xs: "none", md: "block" },
                borderRadius: "250px",
                background: "white",
                boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              }}
              alt="Its a login lander image."
              src="/assets/images/ann.png"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
