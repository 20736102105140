
import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginRequest } from '../../../pages/login';
import { PostApi } from '../../../services/api-service';
import { AxiosError } from 'axios';
import { hideLoader, showLoader } from '../../reducers/loaderSlice';

export const loginAPI = createAsyncThunk("login",
    async (payload: LoginRequest, { rejectWithValue, dispatch }) => {
        try {
            dispatch(showLoader())
            const response = await PostApi(`/login`, payload);
            dispatch(hideLoader())
            return response.data;
        } catch (error) {
            dispatch(hideLoader())
            if (error instanceof AxiosError && error.response) {
                const errorResponse = error.response.data;

                return rejectWithValue(errorResponse);
            }

            throw error;
        }
    }
);


