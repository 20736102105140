import { Middleware } from "@reduxjs/toolkit";
import {
  NotificationType,
  showNotification,
} from "../redux/reducers/notificationSlice";
import { logout } from "../redux/reducers/userSlice";

export const axiosMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  async (action: any) => {
    if (action.type.endsWith("/rejected")) {
      const errorMessage = action.payload?.message;
      console.log(action);
      if (
        action.type == "candidates/parseResume/rejected" ||
        action.type == "candidates/create/rejected"
      ) {
        dispatch(
          showNotification({
            type: NotificationType.Error,
            message:
              "Some files may still be open or locked. Please close any open files and try again.",
          })
        );
      } else if (errorMessage) {
        dispatch(
          showNotification({
            type: NotificationType.Error,
            message: errorMessage,
          })
        );
      } else if (action.payload?.error == "Invalid token") {
        dispatch(
          showNotification({
            type: NotificationType.Error,
            message: "Session Expired! Please login again.",
          })
        );
      } else {
        dispatch(
          showNotification({
            type: NotificationType.Error,
            message:
              "Sorry, something went wrong on our end. Please try again later.",
          })
        );
      }
    } else if (action.type.endsWith("/fulfilled")) {
      const successMessage = action.payload?.message;
      if (successMessage) {
        dispatch(
          showNotification({
            type: NotificationType.Success,
            message: successMessage,
          })
        );
      }
    }

    return next(action);
  };
