export const RouterLinks = {
  GLOBAL: "/",
  DEFAULT: "/",
  LOGIN: "login",
  positions: {
    PATH: "positions",
    INDEX: "/positions",
    DEFAULT: "/positions/list",
    EXPIRED: "/positions/expiredList",
    ADD: "add",
    EDIT: "edit/:positionId",
    LIST: "list",
  },
  candidates: {
    PATH: "candidates",
    INDEX: "/candidates",
    DEFAULT: "/candidates/list",
    ADD: "add",
    EDIT: "edit/:candidateId",
    LIST: "list",
  },
  feedback: {
    PATH: "feedback",
    INDEX: "/feedback",
    DEFAULT: "/feedback/user/:applicationId",
    RESUMERATING: "/feedback/resume/:applicationId",
  },
  forgotpassword: {
    PATH: "",
    DEFAULT: "/forgot-password/:id/:token",
    SENDEMAIL: "/password/generatelink",
  },
};
