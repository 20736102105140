import { combineReducers } from "redux";

import usersReducer from "./userSlice";
import notificationReducer from "./notificationSlice";
import loaderReducer from "./loaderSlice";
import positionsSlice from "./positionsSlice";
import candidatesSlice from "./candidateSlice";

const rootReducer = combineReducers({
  usersData: usersReducer,
  notification: notificationReducer,
  loader: loaderReducer,
  positions: positionsSlice,
  candidates: candidatesSlice
});

export default rootReducer;
