import React, { Suspense, lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";

import { RouterLinks } from "../utilities/routes";
import { JSX } from "react/jsx-runtime";
import SuspenseLoader from "../components/SuspenseLoader";
import BaseLayout from "../layouts/BaseLayout";
import AuthLayout from "../layouts/AuthLayout";
import SidebarLayout from "../layouts/SidebarLayout";
import ForgotPassword from "../pages/forgotpassword";

const Loader = (Component: any) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// const Overview = Loader(lazy(() => import('src/content/overview')));

const Login = Loader(lazy(() => import("../pages/login")));
const PositionsList = Loader(lazy(() => import("../pages/positions/list")));
const ExpiredPositionsList = Loader(
  lazy(() => import("../pages/positions/expiredList"))
);
const AddPosition = Loader(lazy(() => import("../pages/positions/add")));

const CandidatesList = Loader(lazy(() => import("../pages/candidates/list")));
const AddCandidates = Loader(lazy(() => import("../pages/candidates/add")));

const Feedback = Loader(lazy(() => import("../pages/feedback")));
const ResumeFeedback = Loader(lazy(() => import("../pages/resumeReport")));
// Dashboards

const routes: RouteObject[] = [
  {
    path: RouterLinks.GLOBAL,
    element: <BaseLayout />,
    children: [
      {
        path: RouterLinks.DEFAULT,
        element: <Navigate to={RouterLinks.LOGIN} replace />,
      },

      {
        path: RouterLinks.LOGIN,
        element: <Login />,
      },
    ],
  },
  {
    path: `${RouterLinks.positions.PATH}`,
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to={RouterLinks.positions.DEFAULT} replace />,
      },
      {
        path: `${RouterLinks.positions.ADD}`,
        element: <AddPosition />,
      },
      {
        path: `${RouterLinks.positions.EDIT}`,
        element: <PositionsList />,
      },
      {
        path: `${RouterLinks.positions.LIST}`,
        element: <PositionsList />,
      },
      {
        path: `${RouterLinks.positions.EXPIRED}`,
        element: <ExpiredPositionsList />,
      },
    ],
  },
  {
    path: `${RouterLinks.candidates.PATH}`,
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to={RouterLinks.candidates.DEFAULT} replace />,
      },
      {
        path: `${RouterLinks.candidates.ADD}`,
        element: <AddCandidates />,
      },
      {
        path: `${RouterLinks.candidates.EDIT}`,
        element: <AddCandidates />,
      },
      {
        path: `${RouterLinks.candidates.LIST}`,
        element: <CandidatesList />,
      },
    ],
  },
  {
    path: `${RouterLinks.feedback.PATH}`,
    element: <BaseLayout />,
    children: [
      {
        path: "",
        element: <Navigate to={RouterLinks.feedback.DEFAULT} replace />,
      },
      {
        path: `${RouterLinks.feedback.DEFAULT}`,
        element: <Feedback />,
      },
      {
        path: `${RouterLinks.feedback.RESUMERATING}`,
        element: <ResumeFeedback />,
      },
    ],
  },
  {
    path: `${RouterLinks.forgotpassword.PATH}`,
    element: <BaseLayout />,
    children: [
      {
        path: `${RouterLinks.forgotpassword.DEFAULT}`,
        element: <ForgotPassword type="change-password" />,
      },
      {
        path: `${RouterLinks.forgotpassword.SENDEMAIL}`,
        element: <ForgotPassword type="send-email" />,
      },
    ],
  },
];

export { routes };
