import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";
import Header from "../../components/Header";
import Sidedbar from "./Sidebar";

const drawerWidth = 240;

// import Sidebar from './Sidebar';

interface SidebarLayoutProps {
  children?: React.ReactNode;
  title?: string;
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ title }) => {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header />

        <Sidedbar />
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: "white", pt: 3, pl: 1 }}
        >
          {/* <Toolbar /> */}
          <Box sx={{ mt: (theme) => theme.spacing(5) }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SidebarLayout;
