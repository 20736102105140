// store.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/reducer";
import { axiosMiddleware } from "../services/middleware";
import { authMiddleware } from "../services/authMiddleware";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(axiosMiddleware)
    .concat(authMiddleware),
});

export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch