import "./App.css";
// import { authStatus } from './redux/reducers/userSlice';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { routes } from "./routes";
import Header from "./components/Header";
import NotificationBar from "./components/NotificationBar";
import Loading from "./components/Loading";
import { useEffect } from "react";
import { updateUser } from "./redux/reducers/userSlice";
// import { Box, styled } from '@mui/material';

// const ContentItem = styled(Box)(({ theme }) => ({
//   paddingTop: theme.spacing(1),
// }));

function App() {
  const { authenticated } = useSelector((state: RootState) => state.usersData);
  const content = useRoutes(routes);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const res = localStorage.getItem("accessToken");
    if (res) {
      dispatch(
        updateUser({
          authToken: res,
        })
      );
    }
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    const res = localStorage.getItem("accessToken");
    if (!authenticated && !res) {
      const paths = location.pathname.split("/");
      paths.shift();
      if (paths.length > 0) {
        if (paths[0] !== "login" && paths[0] !== "forgot-password") {
          navigate("/login");
        }
      }
    }

    return () => {};
  }, [authenticated]);

  return (
    <>
      <NotificationBar />
      <Loading />
      {/* {authenticated ? <Header /> : <></>} */}
      {content}
      {/* {state.authenticated ?
        <ContentItem >
          {content}
        </ContentItem> :
        { content }
      } */}
    </>
  );
}

export default App;
