import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loginAPI } from "../actions/user";
import { AuthState, Status, User } from "../actions/user/iAuth";

const initialState: AuthState = {
  authenticated: false,
  loading: false,
  status: Status.NotLoggedIn,
  user: null
}

const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.authenticated = true;
      state.status = Status.LoggedIn;
    },
    authStatus: (state, action: PayloadAction<boolean>) => {
      state.authenticated = action.payload;
      state.status = Status.LoggedIn;
    },
    logout: (state) => {
      localStorage.removeItem('accessToken')
      state.authenticated = false;
      state.user = null;
      state.status = Status.NotLoggedIn;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAPI.pending, (state) => {
        state.status = Status.InProgress;
      })
      .addCase(loginAPI.fulfilled, (state, action: PayloadAction<User>) => {
        localStorage.setItem('accessToken', (action.payload.authToken))
        state.status = Status.LoggedIn
        state.user = action.payload;
        state.authenticated = true;
      })
      .addCase(loginAPI.rejected, (state, action) => {
        state.status = Status.Failed;
        state.authenticated = false;
        // state.error = action.error.message;
      })
  },
});

export const { updateUser, authStatus, logout } = userSlice.actions;

export default userSlice.reducer;

