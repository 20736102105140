import { Middleware } from "@reduxjs/toolkit";
import { logout } from "../redux/reducers/userSlice";

export const authMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  async (action: any) => {
    // if (action.type.endsWith("/rejected") && action.payload.error === "Access denied") {

    //     console.log('redirect to login')

    // } else
    if (
      action.type.endsWith("/rejected") &&
      action?.payload?.error === "Invalid token"
    ) {
      dispatch(logout());
    }

    return next(action);
  };
