import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import menuItems, { MenuItem, MenuItems } from "../MenuItems";
export default function SidebarList() {
  const location = useLocation();

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      // subheader={
      //     <ListSubheader component="div" id="nested-list-subheader">
      //         Nested List Items
      //     </ListSubheader>
      // }
    >
      {[...menuItems].map((b, i) => (
        <NavListItem key={i} path={location.pathname} item={b} />
      ))}
    </List>
  );
}

const NavListItem: React.FC<{ path: string; item: MenuItems }> = ({
  path,
  item,
}) => {
  const Icon = item.icon;
  const partialMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: false,
        },
        path
      )
    : false;

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const subItems = item.items ? item.items : [];

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          bgcolor: (theme) =>
            partialMatch ? theme.palette.secondary.main : "transparent",
          ":hover": {
            bgcolor: (theme) =>
              partialMatch ? theme.palette.secondary.main : "transparent",
            borderColor: (theme) =>
              partialMatch ? theme.palette.primary.main : "transparent",
            color: (theme) =>
              partialMatch
                ? theme.palette.primary.contrastText
                : theme.palette.primary.dark,
          },
          borderRadius: 2,
          mt: 1,
        }}
      >
        <ListItemIcon sx={{ minWidth: 35 }}>
          {item.icon ? <Icon /> : <></>}
        </ListItemIcon>
        <ListItemText primary={item.heading} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{ borderLeft: "1px solid black", ml: 2, mt: 2 }}
        >
          {[...subItems].map((b, i) => (
            <LinkItem item={b} key={i} path={path} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const LinkItem: React.FC<{ item: MenuItem; path: string }> = ({
  item,
  path,
}) => {
  const navigate = useNavigate();
  const navi = (link: string) => navigate(link);

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true,
        },
        path
      )
    : false;

  return (
    <ListItemButton
      sx={{
        bgcolor: (theme) =>
          exactMatch ? theme.palette.primary.main : "transparent",
        ":hover": {
          border: "1px solid",
          borderColor: (theme) => theme.palette.primary.main,
          bgcolor: (theme) =>
            exactMatch ? theme.palette.primary.main : "transparent",
        },
        color: (theme) =>
          exactMatch
            ? theme.palette.primary.contrastText
            : theme.palette.primary.dark,

        pl: 2,
        ml: 2,
        borderRadius: 2,
      }}
      onClick={() => navi(item.link ? item.link : "")}
    >
      <ListItemText primary={item.name} />
    </ListItemButton>
  );
};
