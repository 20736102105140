import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { candidatesListAPI, candidatesListByPositionAPI, createCandidatesAPI, fileUploadService } from "../actions/candidates";
import { ICandidatesForms, ICandidatesListRes, IResumeRes } from "../actions/candidates/iCandidate";
import { IPositionRes } from "../actions/positions/iPosition";

const initialState: ICandidatesForms = {
  data: {
    userList: [],
    totalRecords: 0
  },
  loading: false,
  candidatesCreated: false,
  resumeData: [],
  appliedPosition: null,
  shouldUpdateList: false
  // savedResumes: []
}


const candidatesSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    updateResumes: (state, action: PayloadAction<{ docs: File[] }>) => {
      // state.savedResumes = [];
      // action.payload.docs.forEach((file) => state.savedResumes.push(file))
    },
    updateAppliedPosition: (state, action: PayloadAction<IPositionRes>) => {
      // state.resumeData = [...action.payload.docs];
    },
    shouldUpdateList: (state, action: PayloadAction<boolean>) => {
      state.shouldUpdateList = action.payload;
    },
    resetCandidates: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fileUploadService.pending, (state) => {
        state.loading = true;
      })
      .addCase(fileUploadService.fulfilled, (state, action: PayloadAction<IResumeRes[]>) => {
        state.resumeData = [...action.payload]
        state.loading = false;
      })
      .addCase(fileUploadService.rejected, (state, action) => {
        state.loading = true;
      })

    builder
      .addCase(createCandidatesAPI.pending, (state) => {
        // state.loading = true;
        // state.postionCreated = false;
      })
      .addCase(createCandidatesAPI.fulfilled, (state, action: PayloadAction<string>) => {

        state.candidatesCreated = true;
        // state.loading = false;
        // state.postionCreated = true;
      })
      .addCase(createCandidatesAPI.rejected, (state, action) => {
        // state.loading = true;
        // state.postionCreated = false;
      })

    builder
      .addCase(candidatesListAPI.pending, (state) => {
        // state.loading = true;
        // state.postionCreated = false;
      })
      .addCase(candidatesListAPI.fulfilled, (state, action: PayloadAction<ICandidatesListRes>) => {

        state.data = { ...action.payload }
        // state.data = { ...action.payload }
        // state.loading = false;
        // state.postionCreated = true;
      })
      .addCase(candidatesListAPI.rejected, (state, action) => {
        // state.loading = true;
        // state.postionCreated = false;
      })


    builder
      .addCase(candidatesListByPositionAPI.pending, (state) => {
        state.loading = true;
        // state.postionCreated = false;
      })
      .addCase(candidatesListByPositionAPI.fulfilled, (state, action: PayloadAction<ICandidatesListRes>) => {

        state.data = { ...action.payload }
        // state.data = { ...action.payload }
        state.loading = false;
        // state.postionCreated = true;
      })
      .addCase(candidatesListByPositionAPI.rejected, (state, action) => {
        state.loading = false;
        // state.postionCreated = false;
      })
  },
});

export const { updateResumes, resetCandidates, shouldUpdateList } = candidatesSlice.actions;

export default candidatesSlice.reducer;

