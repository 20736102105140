import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Stack } from "@mui/material";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { hideNotification } from "../../redux/reducers/notificationSlice";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotificationBar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { open, message, type } = useSelector((state: RootState) => state.notification);

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(hideNotification());
    };

    return (
        <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar
                sx={{
                    background: 'linear-gradient(90deg, rgba(255, 167, 34, 0.1) 0 %, rgba(153, 100, 21, 0.01) 61.5 %)'
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
};

export default NotificationBar;