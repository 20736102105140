import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  DeleteApi,
  GetApi,
  PostApi,
  PutApi,
} from "../../../services/api-service";
import { AxiosError } from "axios";
import { hideLoader, showLoader } from "../../reducers/loaderSlice";
import { IPositionPayload, IPositionRes } from "./iPosition";
import { shouldUpdateList } from "../../reducers/positionsSlice";
import {
  NotificationType,
  showNotification,
} from "../../reducers/notificationSlice";

export const positionsListAPI = createAsyncThunk(
  "positions/list",
  async (payload: IPositionPayload, { rejectWithValue, dispatch }) => {
    try {
      // dispatch(showLoader())
      const response = await GetApi(`/getPositions`, {
        params: payload,
      });
      dispatch(hideLoader());
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const createPositionAPI = createAsyncThunk(
  "positions/create",
  async (payload: FormData, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await PostApi(`/createPosition`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      dispatch(hideLoader());
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const updatePositionAPI = createAsyncThunk(
  "positions/update",
  async (payload: IPositionRes, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await PutApi(
        `/updatePosition?positionId=${payload._id}`,
        payload
      );
      dispatch(hideLoader());
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const removePositionsAPI = createAsyncThunk(
  "positions/remove",
  async (data: string[], { rejectWithValue, dispatch }) => {
    try {
      const payload = {
        positions: [...data],
      };
      dispatch(showLoader());
      const response = await DeleteApi(`/removePositions`, {
        data: payload,
      });
      dispatch(hideLoader());
      dispatch(shouldUpdateList(true));
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const getPositionJDById = createAsyncThunk(
  "candidates/getPositionJDById",
  async (payload: { id: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await PostApi(`/getPositionJDById`, payload);
      // dispatch(
      //   showNotification({
      //     type: NotificationType.Success,
      //     message: "JD download requested",
      //   })
      // );
      dispatch(hideLoader());
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);
