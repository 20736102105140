
import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import './loader.scss'

const Loading: React.FC = () => {

    const { open: isOpen, message } = useSelector((state: RootState) => state.loader)

    return (
        <Backdrop open={isOpen} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2000, color: '#fff' }} >
            <CircularProgress color="inherit" />
            {
                message ? <h3>&nbsp; &nbsp; {message}</h3> : null
            }
            {/* <div className="loader"></div> */}
        </Backdrop>
    )
}


export default Loading;
