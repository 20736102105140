import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { positionsListAPI, createPositionAPI } from "../actions/positions";
import { IPositionsForms, IPositionsListRes } from "../actions/positions/iPosition";

const initialState: IPositionsForms = {
  data: {
    data: [],
    page: "0",
    totalRecords: 0
  },
  loading: false,
  postionCreated: false,
  shouldUpdateList: false
}


const positionsSlice = createSlice({
  name: "positions",
  initialState,
  reducers: {
    resetPosition: () => initialState,
    shouldUpdateList: (state, action: PayloadAction<boolean>) => {
      state.shouldUpdateList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(positionsListAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(positionsListAPI.fulfilled, (state, action: PayloadAction<IPositionsListRes>) => {
        const finalData = [...action.payload.data]
        state.data = {
          ...action.payload, data: finalData.length > 0 ? [
            ...finalData.map(b => {
              return { ...b, id: b._id }
            })
          ] : []
        }
        state.loading = false;
      })
      .addCase(positionsListAPI.rejected, (state, action) => {
        state.loading = false;
      })

    builder
      .addCase(createPositionAPI.pending, (state) => {
        state.loading = true;
        state.postionCreated = false;
      })
      .addCase(createPositionAPI.fulfilled, (state, action: PayloadAction<IPositionsListRes>) => {

        state.loading = false;
        state.postionCreated = true;
      })
      .addCase(createPositionAPI.rejected, (state, action) => {
        state.loading = true;
        state.postionCreated = false;
      })
  },
});

export const { resetPosition, shouldUpdateList } = positionsSlice.actions;

export default positionsSlice.reducer;

