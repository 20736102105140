import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  DeleteApi,
  GetApi,
  PostApi,
  PutApi,
} from "../../../services/api-service";
import { AxiosError } from "axios";
import { hideLoader, showLoader } from "../../reducers/loaderSlice";
import { ICandidatePayload } from "./iCandidate";
import {
  NotificationType,
  showNotification,
} from "../../reducers/notificationSlice";
import { shouldUpdateList } from "../../reducers/candidateSlice";

export const candidatesListAPI = createAsyncThunk(
  "candidates/list",
  async (payload: ICandidatePayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await GetApi(`/getusers`, {
        params: payload,
      });
      dispatch(hideLoader());

      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const candidatesListByPositionAPI = createAsyncThunk(
  "candidates/list/position",
  async (payload: ICandidatePayload, { rejectWithValue, dispatch }) => {
    try {
      // dispatch(showLoader())
      const response = await GetApi(
        `/getuserByPosition?positionId=${payload.position}`,
        {
          params: payload,
        }
      );
      // dispatch(hideLoader())
      return response.data;
    } catch (error) {
      // dispatch(hideLoader())
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const createCandidatesAPI = createAsyncThunk(
  "candidates/create",
  async (payload: FormData, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await PostApi(`/createUser`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      dispatch(hideLoader());
      dispatch(
        showNotification({
          type: NotificationType.Success,
          message: "Position created successfully",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const removeCandidatesAPI = createAsyncThunk(
  "candidates/remove",
  async (payload: string[], { rejectWithValue, dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await DeleteApi(`/deleteUser`, {
        data: payload,
      });
      dispatch(hideLoader());
      dispatch(
        showNotification({
          type: NotificationType.Success,
          message: "User deleted successfully",
        })
      );
      dispatch(shouldUpdateList(true));
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const updateCandidatesAPI = createAsyncThunk(
  "candidates/update",
  async (
    payload: {
      id: string;
      name: string;
      email: string;
      phoneNumber: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(showLoader());
      const response = await PutApi(`/editCandidate`, {
        ...payload,
      });
      dispatch(hideLoader());
      dispatch(
        showNotification({
          type: NotificationType.Success,
          message: "Candidate info updated successfully",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const fileUploadService = createAsyncThunk(
  "candidates/parseResume",
  async (payload: FormData, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await PostApi(`/parseResume`, payload, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      dispatch(hideLoader());
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const resendEmail = createAsyncThunk(
  "candidates/resendEmail",
  async (payload: { applicationId: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await PostApi(`/sendEmail`, payload);
      dispatch(
        showNotification({
          type: NotificationType.Success,
          message: "Resend email successfull",
        })
      );
      dispatch(hideLoader());
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const getResumeById = createAsyncThunk(
  "candidates/getResumeById",
  async (payload: { userId: string }, { rejectWithValue, dispatch }) => {
    try {
      dispatch(showLoader());
      const response = await PostApi(`/getUserResumeById`, payload);
      // dispatch(
      //   showNotification({
      //     type: NotificationType.Success,
      //     message: "Resume download requested",
      //   })
      // );
      dispatch(hideLoader());
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);

export const regenerateRating = createAsyncThunk(
  "recruiter/regenerateRating",
  async (
    payload: { candidateId: string; positionId: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(showLoader());
      const response = await PostApi(`/regenerateRating`, payload);
      dispatch(
        showNotification({
          type: NotificationType.Success,
          message:
            "Generate rating requested. Please wait for few mins and refresh the list",
        })
      );
      dispatch(hideLoader());
      return response.data;
    } catch (error) {
      dispatch(hideLoader());
      dispatch(
        showNotification({
          type: NotificationType.Success,
          message:
            "Failed to generate rating. Please wait for few mins and try again",
        })
      );
      if (error instanceof AxiosError && error.response) {
        const errorResponse = error.response.data;

        return rejectWithValue(errorResponse);
      }

      throw error;
    }
  }
);
