import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IconButton, Link, Tooltip } from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export default function HelpIcon() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        margin: "5px",
        marginRight: "15px",
      }}
    >
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          borderRadius: 52,
          background: "#FFF",
          width: "30px",
          height: "30px",
        }}
      >
        <Tooltip id="button-edit" title="Help">
          <SupportAgentIcon
            color="primary"
            sx={{
              borderRadius: 52,
              width: "30px",
              height: "30px",
            }}
          />
        </Tooltip>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          Need Assistance? Contact us at{" "}
          <Link href="mailto: support.ann@prosapiens.in">
            support.ann@prosapiens.in
          </Link>
        </Typography>
      </Popover>
    </div>
  );
}
